import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"

import * as ActiveStorage from "@rails/activestorage"
import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle"
window.bootstrap = bootstrap

Rails.start()
ActiveStorage.start()

window.bootstrap = bootstrap

import "./controllers"
import $ from 'jquery'


// new bootstrap.Popover(document.querySelector('[data-bs-toggle="popover"]'), {
//     trigger: 'hover'
// })

console.log('Include: application-esbuild.js')