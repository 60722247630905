import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ['list', 'product', 'productDetails']

    connect() {
        console.log('Retailer Order: connected')
    }


    showProduct(event) {
        let that = this
        this.listTarget.hidden = true
        this.productTarget.hidden = false

        console.log(event.params.path)

        Rails.ajax({
            type: 'GET',
            url: event.params.path,
            dataType: 'json',
            success: function (response) {
                that.productDetailsTarget.innerHTML = response.html
            },
            error: function (response) {
                console.log('Setting could not be saved.')
            }
        })

    }


    closeProduct(event) {
        this.listTarget.hidden = false
        this.productTarget.hidden = true
    }
}