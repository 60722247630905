import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ['switch']

    connect() {
        console.log('new checkbox active')
    }

    toggle(event) {
        console.log('checkbox#toggle')

        let data = new FormData()
        // user[host_video]

        let that = this;

        data.append(this.switchTarget.name, this.value())

        Rails.ajax({
            type: 'GET',
            url: this.url(this.off_switchTarget),
            dataType: 'json',
            success: function (response) {
                console.log(response.result)
                if (response.result) {
                    that.switchTarget.innerHTML = '<i class="fal fa-check-square"></i>'
                }
                else {
                    that.switchTarget.innerHTML = '<i class="fal fa-square"></i>'
                }
            },
            error: function (response) { console.log('Setting could not be saved.')}
        })
    }


    value() {
        return this.data.get('toggle');
    }

    url(target) {
        console.log(this.data.get('url'))
        return this.data.get('url');

    }

}