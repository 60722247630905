import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    console.log('Dropdown#connect')

    this.dropdown = new bootstrap.Dropdown(this.element, { autoClose: true })

  }

  toggle() {
    this.dropdown.toggle()
  }
}