import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'
import * as bootstrap from 'bootstrap'

export default class extends Controller {
    static targets = ["self", 'modal']
    static values = { prices: String, apply:String, path: String }

    connect() {
        console.log('Box#connect')
    }

    prices(event) {
        console.log('Box#connect: ' + event.target.name)

        let that = this

        Rails.ajax({
            type: 'GET',
            url: that.pricesValue,
            dataType: 'json',
            success: function (response) {
                that.modalTarget.innerHTML = response.html

                var myModal = new bootstrap.Modal(document.getElementById(response.ident), {
                    keyboard: false
                })

                myModal.show()


            },
            error: function (response) {
            }
        })

    }


    applyCode(event) {
        console.log('applyCode#connect: ' + event.target.closest('button').name)

       let that = this

        Rails.ajax({
            type: 'GET',
            url: event.target.closest('button').name,
            dataType: 'json',
            success: function (response) {
                // that.selfTarget.outerHTML  = response.self
                that.modalTarget.innerHTML = response.html

                var myModal = new bootstrap.Modal(document.getElementById(response.ident), {
                    keyboard: false
                })

                myModal.show()
            },
            error: function (response) {
            }
        })

    }

    close(event) {
        console.log('applyCode#connect: ' + event.target.closest('button').name)

        let that = this

        Rails.ajax({
            type: 'GET',
            url: event.target.closest('button').name,
            dataType: 'json',
            success: function (response) {
                // that.selfTarget.outerHTML  = response.self
                that.selfTarget.innerHTML = response.html

                var myModal = new bootstrap.Modal(document.getElementById(response.ident), {
                    keyboard: false
                })

                myModal.show()
            },
            error: function (response) {
            }
        })

    }

    delete() {
        let that = this

        Rails.ajax({
            type: 'DELETE',
            url: that.pathValue,
            dataType: 'json',
            success: function (response) {
                that.selfTarget.outerHTML = ''
            },
            error: function (response) { }
        })
    }

}