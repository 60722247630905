import { Controller } from "@hotwired/stimulus"
import consumer from '../channels/consumer'
import bootstrap from "bootstrap/dist/js/bootstrap.bundle"

export default class extends Controller {
    static targets = [ 'self' ]

    connect() {
        console.log('ToasterController#connect')
    }

    initialize() {
        this.subscription()
    }

    disconnect() {
        this.subscription().unsubscribe()
        this.subscription().disconnected()
    }

    subscription() {
        if (this._subscription == undefined) {
            let _this = this
            this._subscription = consumer.subscriptions.create({channel: "ToasterChannel"}, {
                connected() {
                    // Called when the subscription is ready for use on the server
                    console.log(`ToasterController#connected`)
                },

                disconnected() {
                    // Called when the subscription has been terminated by the server
                    console.log(`disconnected`)
                },

                received(data) {
                    let toastElement = document.getElementById('feed-master-' + data.ident)
                    if (toastElement) {

                        toastElement.outerHTML = data.message

                        console.log(toastElement)

                        let myToast = bootstrap.Toast.getInstance(toastElement)
                        console.log(bootstrap.Toast.getInstance(toastElement))

                        // let toast_new = document.createElement('temp')
                        // toast_new.innerHTML = data.message

                        // toastElement.innerHTML = toast_new.firstChild.innerHTML
                        // toastElement.toast({
                        //     delay: 10000
                        // })
                    } else {
                        _this.selfTarget.innerHTML = _this.selfTarget.innerHTML + data.message
                        // let toastElementNew = document.getElementById('feed-master-' + data.ident)
                        // let myToastNew = bootstrap.Toast.getInstance(toastElementNew)
                        // myToastNew.show()
                        // let toast = bootstrap.Toast.getInstance(toastElement)
                        // console.log(toast)
                        // toast.show()
                    }

                    var toastElList = [].slice.call(document.querySelectorAll('.toast'))
                    var toastList = toastElList.map(function (toastEl) {
                        return new bootstrap.Toast(toastEl)
                    })

                }
            });
        }
        return this._subscription
    }
}