import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="products-table"
export default class extends Controller {
  static targets = ['productDetails', 'productsTable']
  // static values = { url: String, customer: String }


  connect() {
    console.log('products-table#connect')
  }


  show_product() {
    this.productDetailsTarget.hidden = false
    this.productsTableTarget.hidden = true
  }

  hide_product() {
    this.productDetailsTarget.hidden = true
    this.productsTableTarget.hidden = false
  }


}
