import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'
import * as bootstrap from 'bootstrap'

export default class extends Controller {
    static targets = ['modal']
    static values = { url: String, customer: String }

    connect() {
        console.log('Box#connect')
    }

    addToBox(event) {
        console.log('Box#connect: ' + event.target.name)

        let that = this

        Rails.ajax({
            type: 'GET',
            url: event.target.name,
            dataType: 'json',
            success: function (response) {
            },
            error: function (response) {
            }
        })

    }

    newBox() {
        let that = this

        Rails.ajax({
            type: 'GET',
            url: this.urlValue,
            dataType: 'json',
            success: function (response) {
                that.modalTarget.innerHTML = response.html

                var myModal = new bootstrap.Modal(document.getElementById(that.customerValue), {
                    keyboard: false
                })

                myModal.show()

            },
            error: function (response) {
            }
        })

    }


    // OTHER STUFF

    startProcessing() {
        console.log('CustomerProduct#startProcessing >' + this.modalIdentValue)

        var myModal = new bootstrap.Modal(document.getElementById(this.modalIdentValue), {
            keyboard: false
        })

        myModal.show()
        console.log('CustomerProduct#startProcessing')

        Rails.ajax({
            type: 'GET',
            url: this.processValue,
            dataType: 'json',
            success: function (response) { },
            error: function (response) { }
        })

        this.load()
        // this.startRefreshing()
    }

    load() {
        let that = this

        Rails.ajax({
            type: 'GET',
            url: this.urlValue,
            dataType: 'json',
            success: function (response) {
                that.statusTarget.innerHTML = response.html
            },
            error: function (response) {
                console.log('filter failed')
            }
        })
    }

    startRefreshing() {
        console.log('CustomerProduct#startRefreshing >' + this.modalIdentValue )


        this.refreshTimer = setInterval(() => {
            this.load()
        }, 500)
    }


    ///////////////////////////////


    toggle(event) {
        console.log('checkbox#toggle')

        let data = new FormData()
        // user[host_video]

        let that = this;

        data.append(this.switchTarget.name, this.value())

        Rails.ajax({
            type: 'GET',
            url: this.url(this.off_switchTarget),
            dataType: 'json',
            success: function (response) {
                console.log(response.result)
                if (response.result) {
                    that.switchTarget.innerHTML = '<i class="fal fa-check-square"></i>'
                }
                else {
                    that.switchTarget.innerHTML = '<i class="fal fa-square"></i>'
                }
            },
            error: function (response) { console.log('Setting could not be saved.')}
        })
    }


    value() {
        return this.data.get('toggle');
    }

    url(target) {
        console.log(this.data.get('url'))
        return this.data.get('url');

    }

}