import { Controller } from "@hotwired/stimulus"
import {Turbo} from "@hotwired/turbo-rails"

export default class extends Controller {
    static targets = ['amount']

    connect() {
        console.log('Retailer Order: connected')

        this._url = this.data.get('url')
    }

    addOrder(event) {
        let that = this

        console.log('suggest change')

        fetch(this._url + "&quantity=" + this.amountTarget.value, {
            method: 'get',
            headers: {
                Accept: "text/vnd.turbo-stream.html",
            }
        })
            .then(r => r.text())
            .then(html => Turbo.renderStreamMessage(html))


    }

}