import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

console.log('included save')

export default class extends Controller {
    static targets = ['markups', 'content']
    static values = { fetchUrl: String }

    connect() {
        console.log('RetailerMarkups#connected')

        this._url = this.data.get('url')
    }

    showMarkups() {
        let that = this
        console.log('RetailerMarkups#showMarkups')

        Rails.ajax({
            type: 'GET',
            url: this.fetchUrlValue,
            dataType: 'json',
            success: function (response) {
                that.markupsTarget.innerHTML = response.html
                that.markupsTarget.hidden    = false
            },
            error: function (response) {
                console.log('Setting could not be saved.')
            }
        })
    }


    addMarkup(event) {
        let that = this
        console.log(`RetailerMarkups#addMarkups: url: ${event.target.name}`)

        Rails.ajax({
            type: 'GET',
            url: event.target.name,
            dataType: 'json',
            success: function (response) {
                that.contentTarget.innerHTML = that.contentTarget.innerHTML + response.html
            },
            error: function (response) {
                console.log('Setting could not be saved.')
            }
        })
    }

    removeMarkup(event) {
        let that = this
        let url = event.target.closest('button').name
        console.log(`RetailerMarkups#addMarkups: url: ${url}`)

        Rails.ajax({
            type: 'GET',
            url: event.target.name,
            dataType: 'json',
            success: function (response) {
                event.target.closest('tr').hidden = true
            },
            error: function (response) {
                console.log('Setting could not be saved.')
            }
        })


    }

}