import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ['input_field', 'off_switch', 'on_switch']
    static values = { dataType:String }

    connect() {
        console.log(`data type: ${this.dataTypeValue}`)

        if(this.dataTypeValue) {
            this._method = this.dataTypeValue
        } else {
            this._method = 'json'
        }
    }

    save_changes(event) {
        let data = new FormData()
        // user[host_video]

        alert("name " + this.input_fieldTarget.name);

        data.append(this.input_fieldTarget.name, this.value())

        Rails.ajax({
            type: 'PATCH',
                method: this.dataTypeValue,
            url: this.url(),
            dataType: 'json',
            data: data,
            success: function (response) { console.log('Setting saved.') },
            error: function (response) { console.log('Setting could not be saved.')}
        })
    }

    // TODO - Update the user management to new target (see radio way)!
    switch_on(event) {
        let data = new FormData()
        // user[host_video]

        let that = this;

        data.append(this.off_switchTarget.name, '1')

        Rails.ajax({
            type: 'PATCH',
            method: this.dataTypeValue,
            url: this.url(this.off_switchTarget),
            dataType: 'json',
            data: data,
            success: function (response) {

                var on = document.getElementById(that.element.getAttribute('id') + '-on');
                var off = document.getElementById(that.element.getAttribute('id') + '-off');

                on.style.display = 'inline';
                off.style.display = 'none';
            },
            error: function (response) { console.log('Setting could not be saved.')}
        })
    }

    switch_off(event) {
        let data = new FormData()
        let that = this;

        data.append(this.on_switchTarget.name, '0')

        Rails.ajax({
            type: 'PATCH',
            method: this.dataTypeValue,
            url: this.url(this.on_switchTarget),
            dataType: 'json',
            data: data,
            success: function (response) {
                var on = document.getElementById(that.element.getAttribute('id') + '-on');
                var off = document.getElementById(that.element.getAttribute('id') + '-off');

                off.style.display = 'inline';
                on.style.display = 'none';
            },
            error: function (response) { console.log('Setting could not be saved.')}
        })
    }



    value() {
        switch (this.input_fieldTarget.type) {
            case "checkbox":
                return this.input_fieldTarget.checked == true ? 1 : 0
            case "select-one":
            case "link":
                return this.input_fieldTarget.value == '0' ? 1 : 0
        }
    }

    url(target) {
        return target.closest('span').getAttribute('url');
    }


    radio_on(event) {
        console.log('#radio_on')

        let data = new FormData()
        let that = this;

        data.append(this.off_switchTarget.name, this.off_switchTarget.getAttribute('value'));

        Rails.ajax({
            type: 'PATCH',
            method: 'turbo_stream',
            url: this.url(this.off_switchTarget),
            dataType: that._method,
            data: data,
            success: function (response) {
                var elements = document.querySelectorAll('[data-name="' + that.off_switchTarget.name + '-on"]'), i

                for (i = 0; i < elements .length; ++i) {
                    elements[i].style.display = 'none';
                }

                var elements = document.querySelectorAll('[data-name="' + that.off_switchTarget.name + '-off"]'), i

                for (i = 0; i < elements .length; ++i) {
                    elements[i].style.display = 'inline';
                }

                var on = document.getElementById(that.element.getAttribute('id') + '-on');
                var off = document.getElementById(that.element.getAttribute('id') + '-off');

                on.style.display = 'inline';
                off.style.display = 'none';

                // $('#credit-failures').replaceWith(response.getElementById("credit-failures").outerHTML);
            },
            error: function (response) { console.log('Setting could not be saved.')}
        })
    }

    radio_off(event) {
        let data = new FormData()
        let that = this;

        data.append(this.on_switchTarget.name, this.on_switchTarget.getAttribute('value'))

        Rails.ajax({
            type: 'PATCH',
            method: this.dataTypeValue,
            url: this.url(this.on_switchTarget),
            dataType: 'json',
            data: data,
            success: function (response) {
                var on = document.getElementById(that.element.getAttribute('id') + '-on');
                var off = document.getElementById(that.element.getAttribute('id') + '-off');

                off.style.display = 'inline';
                on.style.display = 'none';

                // $('#credit-failures').replaceWith(response.getElementById("credit-failures").outerHTML);
            },
            error: function (response) { console.log('Setting could not be saved.')}
        })
    }


}